import { render, staticRenderFns } from "./GenericBlock.vue?vue&type=template&id=5deb0433&scoped=true&"
import script from "./GenericBlock.vue?vue&type=script&lang=js&"
export * from "./GenericBlock.vue?vue&type=script&lang=js&"
import style0 from "./GenericBlock.vue?vue&type=style&index=0&id=5deb0433&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5deb0433",
  null
  
)

export default component.exports