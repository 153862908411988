<template>
    <div class="block" :class="{ clear_styles: clearStyles }"><slot></slot></div>
</template>

<script>
export default {
    name: "GenericBlock",
    props: {
        clearStyles: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../style_variables/style_variables.scss";

.block {
    background: $white;
    border: 1px solid $grey_alabaster;
    border-radius: 8px;
    padding: 0 24px;
    width: 100%;
    max-width: 950px;

    &.clear_styles {
        padding: 0;
        border: none;
    }
}
</style>
