var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SettingsBlock',{attrs:{"title":_vm.$t('cp__custom_report_download__report_type_title')}},[_c('SettingsSection',{attrs:{"label":_vm.$t('cp__custom_report_download__report_type'),"helpText":_vm.$t('cp__custom_report_download__report_type_subtitle')}},[_c('RadioList',{attrs:{"required":"","enableExternalValidation":"","options":[
                {
                    value: 'questionnaire',
                    displayValue: _vm.$t('cp__custom_report_download__report_type__questionnaire'),
                },
                {
                    value: 'custom',
                    displayValue: _vm.$t('cp__custom_report_download__report_type__custom_report'),
                } ]},model:{value:(_vm.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data.type),callback:function ($$v) {_vm.$set(_vm.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data, "type", $$v)},expression:"$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data.type"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }