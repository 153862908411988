<template>
    <div class="download">
        <div class="status">
            <h4 class="title" v-html="titleText"></h4>
            <h5 class="subtitle" v-html="subtitleText"></h5>
            <LoaderCircular v-if="status === 'downloading'" />
            <font-awesome-icon
                v-else-if="status === 'success'"
                size="3x"
                class="icon-success"
                :icon="['fas', 'check-circle']"
            />
            <font-awesome-icon
                v-else-if="status === 'failed'"
                size="3x"
                class="icon-failed"
                :icon="['fas', 'times-circle']"
            />
        </div>
    </div>
</template>

<script>
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import b64toBlob from "b64-to-blob";
import { saveAs } from "file-saver";
import exportQuestionnaireReport from "@/graphql/exportQuestionnaireReport.gql";
import exportCustomReport from "@/graphql/reports/exportCustomReport.gql";

import formatLocalisedDate from "@/helpers/formatLocalisedDate";

library.add(faCheckCircle, faTimesCircle);

export default {
    name: "Download",
    components: {
        LoaderCircular,
        FontAwesomeIcon,
    },
    data() {
        return {
            status: "downloading", // downloading, success, failed
        };
    },
    computed: {
        ...mapGetters({
            language: "LanguageStore/getLanguage",
        }),
        isCustomReport() {
            return (
                this.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data?.type ===
                "custom"
            );
        },
        reportType() {
            if (this.isCustomReport) {
                return this.$t(
                    "cp__questionnaire_reports__download__subtitle_downloading_custom_report"
                );
            }
            return this.$t(
                "cp__questionnaire_reports__download__subtitle_downloading_questionnaire"
            );
        },
        titleText() {
            if (this.status === "failed") {
                return this.$t("cp__questionnaire_reports__download__title_failed", {
                    reportType: this.reportType,
                });
            }
            if (this.status === "downloading") {
                return this.$t("cp__questionnaire_reports__download__title_downloading", {
                    reportType: this.reportType,
                });
            }
            if (this.status === "success") {
                return this.$t("cp__questionnaire_reports__download__title_success", {
                    reportType: this.reportType,
                });
            }
            return "";
        },
        selectedCustomReport() {
            return this.$store.state.DownloadReportsStore.customReports.find(
                (report) =>
                    report.id ===
                    this.$store.state.DownloadReportsStore.steps.SELECT_CUSTOM_REPORT.data?.report
            );
        },
        subtitleText() {
            if (this.status === "failed")
                return this.$t("cp__questionnaire_reports__download__subtitle_failed");

            const { steps } = this.$store.state.DownloadReportsStore;

            const reportName = this.isCustomReport
                ? this.selectedCustomReport.name
                : steps.SELECT_QUESTIONNAIRE.data.name;
            const { start, end } = steps.SCHEDULE_DATES.data;

            let fileType = null;
            if (this.selectedCustomReport?.outputType) {
                if (this.selectedCustomReport?.outputType === "xls_dump") fileType = "excel";
                else fileType = this.selectedCustomReport.outputType;
            } else {
                fileType = steps.SELECT_TYPE.data.type;
            }

            if (this.status === "downloading") {
                let translationName = "cp__questionnaire_reports__download__subtitle_downloading";
                if (!this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.start) {
                    translationName =
                        "cp__questionnaire_reports__download__subtitle_downloading_all";
                }
                return this.$t(translationName, {
                    reportType: this.reportType,
                    questionnaireName: reportName,
                    startDate: formatLocalisedDate(start, this.language),
                    endDate: formatLocalisedDate(end, this.language),
                    fileType: fileType.toUpperCase(),
                });
            }

            if (this.status === "success") {
                let translationName = "cp__questionnaire_reports__download__subtitle_success";
                if (!this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.start) {
                    translationName = "cp__questionnaire_reports__download__subtitle_success_all";
                }
                return this.$t(translationName, {
                    reportType: this.reportType,
                    questionnaireName: reportName,
                    startDate: formatLocalisedDate(start, this.language),
                    endDate: formatLocalisedDate(end, this.language),
                    fileType: fileType.toUpperCase(),
                });
            }

            return "";
        },
    },
    methods: {
        download(base64EncodedFile) {
            const { steps } = this.$store.state.DownloadReportsStore;
            const { start, end } = steps.SCHEDULE_DATES.data;
            const type = this.isCustomReport
                ? this.selectedCustomReport.outputType
                : steps.SELECT_TYPE.data.type;

            let fileName = "questionnaire-";
            if (this.isCustomReport) {
                fileName = `${this.selectedCustomReport.name.replaceAll(" ", "-").toLowerCase()}_`;
            }
            if (start && end) {
                fileName += formatLocalisedDate(start, "nl");
                fileName += "-";
                fileName += formatLocalisedDate(end, "nl");
            }
            fileName += type === "excel" || type === "xls_dump" ? ".xlsx" : ".pdf";

            saveAs(b64toBlob(base64EncodedFile), fileName);
        },
        async exportQuestionnaireReport() {
            const { steps } = this.$store.state.DownloadReportsStore;
            const { selectedQuestionnaire } = steps.SELECT_QUESTIONNAIRE.data;
            const { start, end } = steps.SCHEDULE_DATES.data;
            const { type } = steps.SELECT_TYPE.data;

            try {
                const { data } = await this.$apollo.mutate({
                    mutation: exportQuestionnaireReport,
                    variables: {
                        input: {
                            questionnaireId: selectedQuestionnaire.id.replace(/riq_/, ""), // The id's the query gives aren't the same id's used for the mutation.
                            startDate: start,
                            endDate: end,
                            fileType: type,
                        },
                    },
                });

                this.download(data.exportQuestionnaireReport.data);
                this.status = "success";
            } catch (errors) {
                this.status = "failed";
            }
        },
        async exportCustomReport() {
            const { steps } = this.$store.state.DownloadReportsStore;
            const { start, end } = steps.SCHEDULE_DATES.data;
            const reportId = steps.SELECT_CUSTOM_REPORT.data.report;

            try {
                const { data } = await this.$apollo.mutate({
                    mutation: exportCustomReport,
                    variables: {
                        input: {
                            reportId,
                            startDate: start,
                            endDate: end,
                            exportType: this.getExportType(this.selectedCustomReport),
                        },
                    },
                });

                this.download(data.exportCustomReport.data);
                this.status = "success";
            } catch (errors) {
                this.status = "failed";
            }
        },
        getExportType(report) {
            if (report.outputType === "pdf") {
                return "PDF";
            }
            if (report.outputType === "xls") {
                return "EXCEL";
            }
            if (report.outputType === "xls_dump") {
                return "EXCEL_DUMP";
            }
            return null;
        },
    },
    beforeCreate() {
        this.$store.commit("DownloadReportsStore/setNextStepPredicate", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.DOWNLOAD,
            predicate: () => this.status !== "downloading",
        });
    },
    async mounted() {
        if (this.isCustomReport) {
            this.exportCustomReport();
        } else {
            this.exportQuestionnaireReport();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";
.download {
    width: 100%;
    padding: 64px 0;
}
.status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    * {
        max-width: 624px;
    }

    .title {
        @extend %headline5_style;
        margin-top: 0;
    }

    .subtitle {
        @extend %body3_style;
        font-size: 0.875rem;
        margin-bottom: 60px;
    }

    .icon-success {
        color: $green;
    }

    .icon-failed {
        color: $red;
    }
}
</style>
