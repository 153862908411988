<template>
    <SettingsBlock :title="$t('cp__custom_report_download__report_type_title')">
        <SettingsSection
            :label="$t('cp__custom_report_download__report_type')"
            :helpText="$t('cp__custom_report_download__report_type_subtitle')"
        >
            <RadioList
                v-model="$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data.type"
                required
                enableExternalValidation
                :options="[
                    {
                        value: 'questionnaire',
                        displayValue: $t('cp__custom_report_download__report_type__questionnaire'),
                    },
                    {
                        value: 'custom',
                        displayValue: $t('cp__custom_report_download__report_type__custom_report'),
                    },
                ]"
            />
        </SettingsSection>
    </SettingsBlock>
</template>

<script>
import RadioList from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/RadioList.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";

export default {
    name: "SelectReportType",
    components: {
        RadioList,
        SettingsBlock,
        SettingsSection,
    },
    beforeCreate() {
        this.$store.commit("DownloadReportsStore/setNextStepPredicate", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_REPORT_TYPE,
            predicate: () =>
                !!this.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data?.type,
        });

        if (this.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data) return;

        this.$store.commit("DownloadReportsStore/setData", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_REPORT_TYPE,
            data: {
                type: null,
            },
        });
    },
};
</script>
