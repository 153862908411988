import { format } from "date-fns";

/**
 * @param date
 * @param locale
 * @returns {string}
 */
export default (date, locale) => {
    if (!date) return "";
    if (locale === "en") return format(date, "dd/MM/yyyy");
    if (locale === "nl") return format(date, "dd-MM-yyyy");
};
