<template>
    <SettingsBlock :title="$t('cp__questionnaire_reports__schedule_dates__title')">
        <div class="checkbox">
            <Checkbox
                :label="$t('cp__research_invitations__checkbox_download_all')"
                @click="clickCheckbox"
                v-model="checkDownloadAllData"
            />
        </div>
        <SettingsSection
            class="schedule_date_input"
            :label="$t('cp__questionnaire_reports__schedule_dates__label')"
            :helpText="$t('cp__questionnaire_reports__schedule_dates__subtitle')"
        >
            <div class="date_input_container">
                <DateInput
                    :key="dateInputKey"
                    v-model="$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data"
                    mode="date"
                    isRange
                    isRequired
                    :placeholder="$t('inputs__date__select_date')"
                    :max-date="new Date()"
                    :locale="getLanguageCultureCode"
                    :disabled="!!checkDownloadAllData"
                />
                <p class="info-text" v-if="!!checkDownloadAllData">
                    {{ $t("cp__research_invitations__checkbox_download_all_confirm") }}
                </p>
            </div>
        </SettingsSection>
    </SettingsBlock>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Checkbox from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue";
import DateInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/DateInput.vue";
import formatLocalisedDate from "@/helpers/formatLocalisedDate";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";

export default {
    name: "ScheduleDates",
    components: {
        DateInput,
        SettingsBlock,
        SettingsSection,
        Checkbox,
    },
    data() {
        return {
            checkDownloadAllData: false,
            dateInputKey: 0,
        };
    },
    computed: {
        ...mapState({
            stepId: (state) => state.DownloadReportsStore.stepIds.SCHEDULE_DATES,
        }),
        ...mapGetters({
            getLanguageCultureCode: "LanguageStore/getLanguageCultureCode",
            language: "LanguageStore/getLanguage",
        }),
    },
    methods: {
        formatLocalisedDate,
        clickCheckbox(newValue) {
            if (newValue) {
                this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.start = null;
                this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.end = null;
                this.dateInputKey += 1;
            }
        },
    },
    beforeCreate() {
        this.$store.commit("DownloadReportsStore/setNextStepPredicate", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SCHEDULE_DATES,
            predicate: () =>
                (this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data &&
                    this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.start !==
                        null &&
                    this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.end !==
                        null) ||
                this.checkDownloadAllData === true,
        });

        this.$store.commit("DownloadReportsStore/setMovingBackRequiresConfirmation", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SCHEDULE_DATES,
            predicate: () =>
                this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.start !== null ||
                this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data.end !== null,
        });

        // preserve step data on component init
        if (this.$store.state.DownloadReportsStore.steps.SCHEDULE_DATES.data) return;

        this.$store.commit("DownloadReportsStore/setData", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SCHEDULE_DATES,
            data: {
                start: null,
                end: null,
            },
        });
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables";

.date_input_container {
    max-width: 330px;
}
.checkbox {
    margin-top: 20px;
}

.info-text {
    @extend %body2_style;
}
</style>
