<template>
    <GenericPage
        class="questionnaire-reports"
        :loading="initialDataLoading"
        :headerProps="{
            title: $t('cp__questionnaire_reports__title'),
            subtitle: $t('cp__questionnaire_reports__subtitle'),
            disableBackButton: true,
        }"
    >
        <ConfirmPopup
            v-if="confirmPreviousPopupActive"
            :title="$t('cp__research_invitations__previous_confirm__title')"
            :message="$t('cp__research_invitations__previous_confirm__message')"
            :confirm-btn="$t('cp__research_invitations__previous_confirm__confirm')"
            :cancel-btn="$t('cp__generic__cancel')"
            @confirm="proceedAfterPopupConfirm"
            @cancel="cancelPopup"
        />
        <div class="container" v-if="!initialDataLoading">
            <GenericBlock style="margin-bottom: 40px">
                <Stepper
                    :stepSequence="stepSequence"
                    :activeStep="activeStep"
                    :allStepsCompleted="allStepsCompleted"
                    @moveBackTo="moveBackTo($event.stepId)"
                />
            </GenericBlock>
            <GenericBlock
                :clearStyles="
                    [
                        stepIds.SCHEDULE_DATES,
                        stepIds.SELECT_TYPE,
                        stepIds.SELECT_REPORT_TYPE,
                        stepIds.SELECT_CUSTOM_REPORT,
                    ].includes(activeStep.id)
                "
            >
                <SelectReportType v-if="activeStep.id === stepIds.SELECT_REPORT_TYPE" />
                <SelectQuestionnaire v-else-if="activeStep.id === stepIds.SELECT_QUESTIONNAIRE" />
                <SelectCustomReport v-else-if="activeStep.id === stepIds.SELECT_CUSTOM_REPORT" />
                <ScheduleDates v-else-if="activeStep.id === stepIds.SCHEDULE_DATES" />
                <SelectType v-else-if="activeStep.id === stepIds.SELECT_TYPE" />
                <Download v-else-if="activeStep.id === stepIds.DOWNLOAD" />
            </GenericBlock>
        </div>
        <SettingsFooter v-if="!initialDataLoading">
            <div class="button-container">
                <Button :disabled="!nextStepIsAllowed" @click.native="handleNext()">
                    {{ nextButtonLabel }}
                </Button>
                <Button
                    v-if="previousStep && !allStepsCompleted"
                    buttonStyle="secondary"
                    @click.native="moveBackTo(previousStep.id)"
                >
                    {{ $t("cp__research_invitations__btn_previous") }}
                </Button>
            </div>
        </SettingsFooter>
    </GenericPage>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import {
    faCalendarDay,
    faChevronRight,
    faCloudDownload,
    faClipboardList,
    faCopy,
} from "@fortawesome/pro-light-svg-icons";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import GenericBlock from "@/components/Global/Molecules/GenericBlock.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import SelectReportType from "@/components/DownloadReports/SelectReportType.vue";
import SelectQuestionnaire from "@/components/DownloadReports/SelectQuestionnaire.vue";
import ScheduleDates from "@/components/DownloadReports/ScheduleDates.vue";
import SelectType from "@/components/DownloadReports/SelectType.vue";
import Download from "@/components/DownloadReports/Download.vue";
import SelectCustomReport from "@/components/DownloadReports/SelectCustomReport.vue";

import Stepper from "@/components/Global/Molecules/Stepper.vue";
import getCustomReports from "@/graphql/reports/getCustomReports.gql";

library.add(faChevronRight, faClipboardList, faCalendarDay, faCloudDownload, faCheckCircle, faCopy);

export default {
    name: "DownloadReports",
    components: {
        ConfirmPopup,
        GenericPage,
        GenericBlock,
        SettingsFooter,
        Stepper,
        SelectCustomReport,
        SelectReportType,
        SelectQuestionnaire,
        ScheduleDates,
        SelectType,
        Download,
        Button,
    },
    data() {
        return {
            initialDataLoading: true,
            confirmPreviousPopupActive: false,
            confirmPreviousPopupTargetStep: true,
        };
    },
    computed: {
        ...mapGetters({
            stepIds: "DownloadReportsStore/stepIds",
            activeStep: "DownloadReportsStore/activeStep",
            allStepsCompleted: "DownloadReportsStore/allStepsCompleted",
            nextStepIsAllowed: "DownloadReportsStore/nextStepIsAllowed",
            nextStep: "DownloadReportsStore/nextStep",
            previousStep: "DownloadReportsStore/previousStep",
            hasPermissions: "AccountStore/hasPermissions",
        }),
        ...mapState({
            steps: (state) => state.DownloadReportsStore.steps,
            stepSequence: (state) => state.DownloadReportsStore.stepSequence,
            stepIds: (state) => state.DownloadReportsStore.stepIds,
        }),
        hasCustomReportsAccess() {
            return this.hasPermissions(["EVALUATION_CUSTOM_REPORTS_ACCESS"]);
        },
        hasPDFAccess() {
            return this.hasPermissions(["EVALUATION_DOWNLOAD_PDF"]);
        },
        hasExcelAccess() {
            return this.hasPermissions(["EVALUATION_DOWNLOAD_EXCEL"]);
        },
        nextButtonLabel() {
            if (this.allStepsCompleted) {
                return this.$t("cp__questionnaire_reports__btn_new");
            }
            if (
                this.activeStep.id === this.stepIds.SELECT_TYPE ||
                (this.activeStep.id === this.stepIds.SCHEDULE_DATES &&
                    this.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data?.type ===
                        "custom")
            ) {
                return this.$t("cp__questionnaire_reports__btn_download");
            }
            return this.$t("cp__research_invitations__btn_next");
        },
    },
    methods: {
        async handleNext() {
            if (this.allStepsCompleted) {
                await this.$store.dispatch("DownloadReportsStore/reset");
            } else if (this.activeStep.id === this.stepIds.SELECT_REPORT_TYPE) {
                if (
                    this.$store.state.DownloadReportsStore.steps.SELECT_REPORT_TYPE.data.type ===
                    "custom"
                ) {
                    this.$store.commit("DownloadReportsStore/activateCustomReportsMode");
                } else {
                    this.$store.commit("DownloadReportsStore/activateQuestionnaireMode");
                }
                await this.$store.dispatch("DownloadReportsStore/next");
            } else {
                await this.$store.dispatch("DownloadReportsStore/next");
            }
        },
        moveBackTo(stepToMoveBackTo) {
            if (this.activeStep.movingBackRequiresConfirmation()) {
                this.confirmPreviousPopupActive = true;
                this.confirmPreviousPopupTargetStep = stepToMoveBackTo;
            } else {
                this.$store.dispatch("DownloadReportsStore/moveBackTo", {
                    stepId: stepToMoveBackTo,
                });
            }
        },
        proceedAfterPopupConfirm() {
            this.$store.dispatch("DownloadReportsStore/moveBackTo", {
                stepId: this.confirmPreviousPopupTargetStep,
            });
            this.confirmPreviousPopupActive = false;
            this.confirmPreviousPopupTargetStep = null;
        },
        cancelPopup() {
            this.confirmPreviousPopupActive = false;
            this.confirmPreviousPopupTargetStep = null;
        },
        async getCustomReports() {
            try {
                const { data } = await this.$apollo.query({
                    query: getCustomReports,
                });

                if (data.customReports?.length > 0) {
                    this.$store.commit("DownloadReportsStore/initStore", {
                        hasCustomReports: true,
                        hasPDFAccess: this.hasPDFAccess,
                        hasExcelAccess: this.hasExcelAccess,
                    });
                    this.$store.state.DownloadReportsStore.customReports = data.customReports;
                } else {
                    this.$store.commit("DownloadReportsStore/initStore", {
                        hasCustomReports: false,
                        hasPDFAccess: this.hasPDFAccess,
                        hasExcelAccess: this.hasExcelAccess,
                    });
                }
                this.initialDataLoading = false;
            } catch (e) {
                this.$store.commit("DownloadReportsStore/initStore", {
                    hasCustomReports: false,
                    hasPDFAccess: this.hasPDFAccess,
                    hasExcelAccess: this.hasExcelAccess,
                });
                this.initialDataLoading = false;
            }
        },
    },
    created() {
        if (this.hasCustomReportsAccess) {
            this.getCustomReports();
        } else {
            this.$store.commit("DownloadReportsStore/initStore", {
                hasCustomReports: false,
                hasPDFAccess: this.hasPDFAccess,
                hasExcelAccess: this.hasExcelAccess,
            });
            this.initialDataLoading = false;
        }

        this.$store.dispatch("DownloadReportsStore/reset");
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../style_variables/style_variables.scss";

.questionnaire-reports {
    position: relative;
    overflow: auto;
    @include viewportHeight(100, calc(56px + 96px));
    @media (max-width: 1000px) {
        @include viewportHeight(100, 144px);
    }
}
.container {
    margin: 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media($isPhone...) {
        margin-left: 16px;
        margin-right: 16px;
    }
}
.button-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
</style>
