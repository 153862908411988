<template>
    <div>
        <div class="questionnaire_block selection_confirmation">
            <div>
                <div class="subtitle3">{{ $t("cp__questionnaire_selected") }}</div>
                <div
                    class="body1"
                    :class="{ no_questionnaire_selected: !data.selectedQuestionnaire }"
                >
                    {{
                        !data.selectedQuestionnaire
                            ? $t("cp__questionnaire_no_questionnaire_selected")
                            : data.selectedQuestionnaire.name
                    }}
                </div>
            </div>
            <LoaderCircular :height="36" v-if="isLoading" />
        </div>
        <TreeView
            v-if="data.treeData"
            class="questionnaire_block"
            :items="data.treeData"
            :onClickHandler="selectQuestionnaire"
            :tooltipTitle="$t('cp__questionnaire_id_tooltip')"
        />
    </div>
</template>

<script>
import TreeView from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/TreeView.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { mapState } from "vuex";
import getReportQuestionnaireCategoryNodes from "../../graphql/reports/getReportQuestionnaireCategoryNodes.gql";

export default {
    components: {
        LoaderCircular,
        TreeView,
    },
    name: "Questionnaire",
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapState({
            data: (state) => state.DownloadReportsStore.steps.SELECT_QUESTIONNAIRE.data,
            stepId: (state) => state.DownloadReportsStore.stepIds.SELECT_QUESTIONNAIRE,
        }),
    },
    methods: {
        async selectQuestionnaire(node) {
            const { SELECT_QUESTIONNAIRE } = this.$store.state.DownloadReportsStore.stepIds;

            if (node.leaf) {
                if (this.data.selectedQuestionnaire) {
                    this.data.selectedQuestionnaire.isActive = false;
                }
                node.isActive = true;
                this.data.selectedQuestionnaire = node;
                this.$store.commit("DownloadReportsStore/setData", {
                    stepId: SELECT_QUESTIONNAIRE,
                    data: this.data,
                });
            } else {
                this.isLoading = true;
                const result = await this.$apollo.query({
                    query: getReportQuestionnaireCategoryNodes,
                    variables: { categoryNodeId: node.id },
                });
                this.prepareDisplayData(result.data.reportQuestionnaireCategoryNodes);
                node.children = result.data.reportQuestionnaireCategoryNodes;
                this.isLoading = false;
            }
        },
        async getInitialTreeData() {
            this.isLoading = true;
            const result = await this.$apollo.query({
                query: getReportQuestionnaireCategoryNodes,
                variables: { categoryNodeId: "rootnode" },
            });
            const treeData = this.prepareDisplayData(result.data.reportQuestionnaireCategoryNodes);
            const data = this.data
                ? { ...this.data, treeData }
                : { selectedQuestionnaire: null, treeData };
            this.$store.commit("DownloadReportsStore/setData", {
                stepId: this.stepId,
                data,
            });
            this.isLoading = false;
        },
        prepareDisplayData(treeData) {
            return treeData.map((node) => {
                if (!node.leaf) {
                    node.children = [];
                    node.isOpen = false;
                }
                node.isActive = false;
                return node;
            });
        },
    },
    beforeCreate() {
        const { SELECT_QUESTIONNAIRE } = this.$store.state.DownloadReportsStore.stepIds;
        this.$store.commit("DownloadReportsStore/setNextStepPredicate", {
            stepId: SELECT_QUESTIONNAIRE,
            predicate: () => !!this.data?.selectedQuestionnaire,
        });

        this.$store.commit("DownloadReportsStore/setMovingBackRequiresConfirmation", {
            stepId: SELECT_QUESTIONNAIRE,
            predicate: () => this.data?.selectedQuestionnaire !== null,
        });

        // preserve step data on component init
        if (this.$store.state.DownloadReportsStore.steps.SELECT_QUESTIONNAIRE.data) return;

        this.$store.commit("DownloadReportsStore/setData", {
            stepId: SELECT_QUESTIONNAIRE,
            data: { selectedQuestionnaire: null, treeData: null },
        });
    },
    created() {
        if (this.data.treeData) return;
        this.getInitialTreeData();
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";

.questionnaire_block {
    margin-top: 30px;
    padding: 24px;
    border: 1px solid $grey_alabaster;
    border-radius: 4px;

    &.selection_confirmation {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &:last-child {
        margin-bottom: 30px;
        padding: 8px 24px 8px 24px;
    }
}
.no_questionnaire_selected {
    color: $grey_bombay;
}
</style>
