<template>
    <SettingsBlock :title="$t('cp__questionnaire_reports__select_type__title')">
        <SettingsSection
            :label="$t('cp__questionnaire_reports__select_type__section_title')"
            :helpText="$t('cp__questionnaire_reports__select_type__section_subtitle')"
        >
            <RadioList
                v-model="$store.state.DownloadReportsStore.steps.SELECT_TYPE.data.type"
                required
                enableExternalValidation
                :options="options"
            />
        </SettingsSection>
    </SettingsBlock>
</template>

<script>
import { mapGetters } from "vuex";
import RadioList from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/RadioList.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";

export default {
    name: "SelectType",
    components: {
        RadioList,
        SettingsBlock,
        SettingsSection,
    },
    computed: {
        ...mapGetters({
            hasPDFAccess: "DownloadReportsStore/hasPDFAccess",
            hasExcelAccess: "DownloadReportsStore/hasExcelAccess",
        }),
        options() {
            const options = [];
            if (this.hasPDFAccess) {
                options.push({
                    value: "pdf",
                    displayValue: this.$t("cp__questionnaire_reports__select_type__option_pdf"),
                });
            }
            if (this.hasExcelAccess) {
                options.push({
                    value: "excel",
                    displayValue: this.$t("cp__questionnaire_reports__select_type__option_excel"),
                });
            }
            return options;
        },
    },
    beforeCreate() {
        this.$store.commit("DownloadReportsStore/setNextStepPredicate", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_TYPE,
            predicate: () => !!this.$store.state.DownloadReportsStore.steps.SELECT_TYPE.data?.type,
        });

        this.$store.commit("DownloadReportsStore/setMovingBackRequiresConfirmation", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_TYPE,
            predicate: () =>
                this.$store.state.DownloadReportsStore.steps.SELECT_TYPE.data?.type !== null,
        });

        // preserve step data on component init
        if (this.$store.state.DownloadReportsStore.steps.SELECT_TYPE.data) return;

        this.$store.commit("DownloadReportsStore/setData", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_TYPE,
            data: { type: null },
        });
    },
};
</script>
