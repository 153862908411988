<template>
    <div class="stepper">
        <template v-for="(step, i) in stepSequence">
            <div class="step" :key="`step_${step.id}`">
                <span v-tippy="{ content: $t(step.label) }">
                    <button
                        class="icon-button"
                        @click="moveToStep(step)"
                        :disabled="!step.completed || allStepsCompleted"
                    >
                        <font-awesome-icon
                            class="icon"
                            :class="{
                                'icon--completed': step.completed,
                                'icon--all-steps-completed': allStepsCompleted,
                                'icon--active': step.id === activeStep.id,
                            }"
                            size="lg"
                            fixed-width
                            :icon="step.icon"
                        />
                    </button>
                </span>
                <span v-if="step.id === activeStep.id" class="step-description">
                    {{ $t(step.label) }}
                </span>
            </div>
            <font-awesome-icon
                v-if="i !== stepSequence.length - 1"
                class="divider"
                :class="{ 'divider--completed': step.completed }"
                :icon="['fal', 'chevron-right']"
                :key="`divider_${step.id}`"
            />
        </template>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    name: "Stepper",
    components: {
        FontAwesomeIcon,
    },
    props: {
        stepSequence: {
            type: Array,
            required: true,
        },
        activeStep: {
            type: Object,
            required: true,
        },
        allStepsCompleted: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        moveToStep(step) {
            if (!step.completed || step.id === this.activeStep) return;
            this.$emit("moveBackTo", { stepId: step.id });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../style_variables/style_variables";

.stepper {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.step {
    display: flex;
    align-items: center;

    .step-description {
        @extend %body2_style;
        letter-spacing: 0;
        line-height: 16px;
        margin-left: 12px;
        width: 110px;
        max-width: 110px;
    }

    .icon-button {
        border: none;
        background: none;
        padding: 0;
        height: 32px;
        font-size: 100%;

        .icon {
            color: $grey_bombay;
            cursor: not-allowed;

            &.icon--completed {
                color: $blue;
                cursor: pointer;
            }
            &.icon--all-steps-completed {
                color: $black;
                cursor: default;
            }
            &.icon--active {
                color: $blue;
                cursor: default;
            }
        }
    }
}

.divider {
    color: $grey_bombay;
    &.divider--completed {
        color: $black;
    }
}
</style>
