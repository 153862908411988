var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericPage',{staticClass:"questionnaire-reports",attrs:{"loading":_vm.initialDataLoading,"headerProps":{
        title: _vm.$t('cp__questionnaire_reports__title'),
        subtitle: _vm.$t('cp__questionnaire_reports__subtitle'),
        disableBackButton: true,
    }}},[(_vm.confirmPreviousPopupActive)?_c('ConfirmPopup',{attrs:{"title":_vm.$t('cp__research_invitations__previous_confirm__title'),"message":_vm.$t('cp__research_invitations__previous_confirm__message'),"confirm-btn":_vm.$t('cp__research_invitations__previous_confirm__confirm'),"cancel-btn":_vm.$t('cp__generic__cancel')},on:{"confirm":_vm.proceedAfterPopupConfirm,"cancel":_vm.cancelPopup}}):_vm._e(),(!_vm.initialDataLoading)?_c('div',{staticClass:"container"},[_c('GenericBlock',{staticStyle:{"margin-bottom":"40px"}},[_c('Stepper',{attrs:{"stepSequence":_vm.stepSequence,"activeStep":_vm.activeStep,"allStepsCompleted":_vm.allStepsCompleted},on:{"moveBackTo":function($event){return _vm.moveBackTo($event.stepId)}}})],1),_c('GenericBlock',{attrs:{"clearStyles":[
                    _vm.stepIds.SCHEDULE_DATES,
                    _vm.stepIds.SELECT_TYPE,
                    _vm.stepIds.SELECT_REPORT_TYPE,
                    _vm.stepIds.SELECT_CUSTOM_REPORT ].includes(_vm.activeStep.id)}},[(_vm.activeStep.id === _vm.stepIds.SELECT_REPORT_TYPE)?_c('SelectReportType'):(_vm.activeStep.id === _vm.stepIds.SELECT_QUESTIONNAIRE)?_c('SelectQuestionnaire'):(_vm.activeStep.id === _vm.stepIds.SELECT_CUSTOM_REPORT)?_c('SelectCustomReport'):(_vm.activeStep.id === _vm.stepIds.SCHEDULE_DATES)?_c('ScheduleDates'):(_vm.activeStep.id === _vm.stepIds.SELECT_TYPE)?_c('SelectType'):(_vm.activeStep.id === _vm.stepIds.DOWNLOAD)?_c('Download'):_vm._e()],1)],1):_vm._e(),(!_vm.initialDataLoading)?_c('SettingsFooter',[_c('div',{staticClass:"button-container"},[_c('Button',{attrs:{"disabled":!_vm.nextStepIsAllowed},nativeOn:{"click":function($event){return _vm.handleNext()}}},[_vm._v(" "+_vm._s(_vm.nextButtonLabel)+" ")]),(_vm.previousStep && !_vm.allStepsCompleted)?_c('Button',{attrs:{"buttonStyle":"secondary"},nativeOn:{"click":function($event){return _vm.moveBackTo(_vm.previousStep.id)}}},[_vm._v(" "+_vm._s(_vm.$t("cp__research_invitations__btn_previous"))+" ")]):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }