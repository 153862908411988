<template>
    <SettingsBlock :title="$t('cp__custom_report_download__select_custom_report_title')">
        <SettingsSection
            :label="$t('cp__custom_report_download__select_custom_report')"
            :helpText="$t('cp__custom_report_download__select_custom_report_subtitle')"
        >
            <Dropdown
                :searchAble="dropdownItems.length > 3"
                :options="dropdownItems"
                :placeholder="$t('cp__custom_report_download__select_custom_report_placeholder')"
                v-model="$store.state.DownloadReportsStore.steps.SELECT_CUSTOM_REPORT.data.report"
            />
        </SettingsSection>
    </SettingsBlock>
</template>

<script>
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";

export default {
    name: "SelectCustomReport",
    components: {
        SettingsBlock,
        SettingsSection,
        Dropdown,
    },
    computed: {
        dropdownItems() {
            return this.$store.state.DownloadReportsStore.customReports.map((report) => ({
                value: report.id,
                displayValue: report.name,
            }));
        },
    },
    beforeCreate() {
        this.$store.commit("DownloadReportsStore/setNextStepPredicate", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_CUSTOM_REPORT,
            predicate: () =>
                !!this.$store.state.DownloadReportsStore.steps.SELECT_CUSTOM_REPORT.data?.report,
        });

        this.$store.commit("DownloadReportsStore/setMovingBackRequiresConfirmation", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_CUSTOM_REPORT,
            predicate: () =>
                this.$store.state.DownloadReportsStore.steps.SELECT_CUSTOM_REPORT.data?.report !==
                null,
        });

        if (this.$store.state.DownloadReportsStore.steps.SELECT_CUSTOM_REPORT.data) return;

        this.$store.commit("DownloadReportsStore/setData", {
            stepId: this.$store.state.DownloadReportsStore.stepIds.SELECT_CUSTOM_REPORT,
            data: { report: null },
        });
    },
};
</script>
